@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind colors;

.App {
  text-align: center;
}

.App-logo {
  width: 200px;
  height: 200px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-nav-header {
  display: flex;
  background: rgb(32, 218, 190);
  color: white;
  height: 65px;
  padding: 0 30px;
  justify-content: center;
  align-items: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.todos-pagination {
  float: right;
  font-size: 12px;
}

.todos-pagination span {
  padding: 3px 5px;
}

.todos-pagination button {
  width: 40px;
  height: 18px;
}

.NavButton {
  height: 65px;
  padding: 0 15px;
  background-color: rgb(32, 218, 190);
  cursor: pointer;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.NavButton:hover {
  background-color: #48a6d9;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  /* transform: translate(0, -50px); */
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog {
  margin: 0.5rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: auto auto;
  }
  .modal-sm {
    max-width: 600px;
  }
  .modal-lg {
    max-width: 800px;
  }
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 992px) {
  .modal-sm {
    max-width: 600px;
  }
  .modal-lg {
    max-width: 800px;
  }
  .modal-xl {
    max-width: 1000px;
  }
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

@media (min-width: 576px) {
  .modal-dialog-centered {
    min-height: calc(100% - (1.5rem * 2));
  }
}

.btn-close {
  background: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 16 16%22 fill=%22%23000%22%3E%3Cpath d=%22M.293.293a1 1 0 011.414.0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293A1 1 0 01.293 14.293L6.586 8 .293 1.707a1 1 0 010-1.414z%22/%3E%3C/svg%3E")
    50%/1em no-repeat;
}
